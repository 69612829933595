import axios from "axios";

export async function getSetting(name) {
  let url = process.env.VUE_APP_BASE_URL + "/api/setting";
  const config = {
    url: url + `/${name}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };
  let val = await axios(config);
  return val.data.value;
}
