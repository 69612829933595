<template>
  <v-app-bar app color="primary" flat dark>
    <!-- <v-container class="py-0 fill-height" fluid> -->
    <v-img :src="immLogo" max-height="35" max-width="35" />
    <v-toolbar-title>
      <div class="hidden-sm-and-down">
        Online Queue Reservation (Ayutthaya Immigration)
      </div>
      <div class="hidden-md-and-up">OQR. Ayutthaya Imm.</div>
    </v-toolbar-title>
    <v-btn icon to="/" plain>
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <!-- LARGE SCREEN -->
    <v-responsive max-width="260" class="hidden-sm-and-down">
      <v-text-field
        dense
        flat
        hide-details
        rounded
        v-model="queueId"
        append-icon="mdi-magnify"
        @click:append="findQueue"
        @keypress.enter="findQueue"
        prefix="Q-"
        solo-inverted
      ></v-text-field>
    </v-responsive>
    <v-btn to="/login" plain class="hidden-sm-and-down">
      <v-icon>mdi-shield-lock</v-icon>
      สำหรับเจ้าหน้าที่
    </v-btn>

    <!-- MENU SMALL SCREEN -->

    <v-menu offset-y :close-on-click="false" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" icon class="hidden-md-and-up">
          <v-icon>mdi-dots-vertical-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              dense
              flat
              hide-details
              rounded
              v-model="queueId"
              append-icon="mdi-magnify"
              @click:append="findQueue"
              @keypress.enter="findQueue"
              prefix="Q-"
              solo-inverted
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/login">
          <v-list-item-icon>
            <v-icon>mdi-shield-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>สำหรับเจ้าหน้าที่</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- </v-container> -->
  </v-app-bar>
</template>

<script>
import immLogo from "../../../assets/immlogo.png";
export default {
  data() {
    return {
      links: ["Dashboard", "Messages", "Profile", "Updates"],
      immLogo: immLogo,
      queueId: ""
    };
  },
  methods: {
    findQueue() {
      // console.log(this.$router.currentRoute);
      // check duplicate route
      if (
        this.$router.currentRoute.name == "QueueResult" &&
        this.$router.currentRoute.query.id == this.queueId
      ) {
        return;
      }

      if (this.$router.currentRoute.name == "QueueResult") {
        this.$router.replace({ query: { id: this.queueId } });
        this.queueId = "";
        return;
      }

      if (this.queueId == "") return;
      this.$router.push({
        name: "QueueResult",
        query: { id: this.queueId }
      });

      this.queueId = "";
    }
  }
};
</script>

<style></style>
