import SecureLS from "secure-ls";

let ls = new SecureLS({
  encodingType: "rc4",
  isCompression: false
});

const appData = {
  get(key) {
    return ls.get(key);
  },

  set(key, val) {
    if (typeof val == "object") val = JSON.stringify(val);
    return ls.set(key, val);
  },

  remove(key) {
    return ls.remove(key);
  },

  removeAll() {
    return ls.removeAll();
  }
};

export default appData;
