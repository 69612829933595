import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { getToken, setToken, removeToken } from "../views/admin/utils/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  },
  state: {
    status: "",
    token: getToken() || "",
    user: {}
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
    },
    get_user(state, user) {
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: process.env.VUE_APP_BASE_URL + "/api/imm/auth",
          data: user,
          method: "POST"
        })
          .then(resp => {
            if (
              resp.data.access_token == undefined ||
              resp.data.access_token == null ||
              resp.data.access_token == ""
            ) {
              commit("auth_error");
              removeToken();
              reject(resp.data);
              return;
            }
            const token = resp.data.access_token;
            setToken(`Bearer ${token}`);
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            commit("auth_success", `Bearer ${token}`);
            this.dispatch("getInfo");
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            removeToken();
            reject(err);
          });
      });
    },
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: process.env.VUE_APP_BASE_URL + "/api/user",
          method: "get"
        })
          .then(res => {
            commit("get_user", res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        removeToken();
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    }
  },
  modules: {}
});
