import Vue from "vue";
import VueRouter from "vue-router";
import HomeMenu from "../views/home_menu";
import store from "../store";
import UserLayout from "../views/layout_user/main";
import AdminLayout from "../views/layout_admin/main";
Vue.use(VueRouter);

const routes = [
  // customer
  {
    path: "/",
    component: UserLayout,
    children: [
      {
        path: "",
        component: HomeMenu
      },
      {
        path: "/callback",
        component: () => import("../views/test")
      },
      {
        path: "agent",
        name: "Agent",
        component: () => import("../views/agent/confirmation.vue")
      },
      {
        path: "line_callback",
        name: "LineCallback",
        component: () => import("../views/agent/linecallback.vue")
      },
      {
        path: "agent_register",
        name: "AgentRegister",
        component: () => import("../views/agent/register.vue")
      },
      {
        path: "register",
        name: "WorkerRegister",
        component: () => import("../views/workers")
      },
      {
        path: "find",
        name: "FindQueue",
        component: () => import("../views/findqueue")
      },
      {
        path: "queue_result",
        name: "QueueResult",
        component: () => import("../views/agent/queueConfirm.vue")
      },
      {
        path: "about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue")
      }
    ]
  },

  // admin route
  { path: "/login", name: "Login", component: () => import("../views/login") },

  {
    path: "/immAdmin",
    component: AdminLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../views/admin/dashboard")
      },
      {
        path: "calendar",
        name: "QueueCalendar",
        component: () =>
          import("../views/admin/queueManangement/calendarview.vue")
      },
      {
        path: "queue_reserve",
        name: "ReserverQueue",
        component: () => import("../views/admin/queueReserve")
      },
      {
        path: "worker_reserve",
        name: "WorkerQueue",
        component: () => import("../views/admin/workerReserve")
      },
      {
        path: "find",
        name: "findData",
        component: () => import("../views/admin/queueSearch")
      },
      {
        path: "print",
        name: "Print",
        component: () => import("../views/admin/print")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "*",
    component: () => import("@/views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
