<template>
  <v-app-bar app color="indigo darken-4" flat dark>
    <v-img :src="immLogo" max-height="35" max-width="35" />
    <v-toolbar-title style="overflow: visible">
      Ayutthaya OQR Admin
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tabs right class="ml-n9 hidden-sm-and-down" color="grey darken-1">
      <v-tab v-for="link in links" :key="link.title" :to="link.to">
        {{ link.title }}
      </v-tab>
      <v-tab @click="logout">
        <v-icon>mdi-lock</v-icon>
        Logout
      </v-tab>
    </v-tabs>

    <!-- MENU SMALL SCREEN -->

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" icon class="hidden-md-and-up">
          <v-icon>mdi-dots-vertical-circle</v-icon>
        </v-btn>
      </template>
      <v-list nav>
        <v-list-item
          v-for="(item, index) in links"
          :key="`menu-${index}`"
          :to="item.to"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <v-row class="pull-right" style="overflow: hidden">
      <v-icon>mdi-account</v-icon>
      {{ user.name }}
    </v-row> -->
  </v-app-bar>
</template>

<script>
import immLogo from "../../../assets/immlogo.png";

export default {
  data() {
    return {
      links: [
        { to: "dashboard", title: "Dashboard" },
        { to: "calendar", title: "จัดการคิว" },
        { to: "find", title: "ค้นหา" },
        { to: "print", title: "พิมพ์รายการ" },
        { to: "queue_reserve", title: "ลงทะเบียนคิว" },
        { to: "worker_reserve", title: "ลงทะเบียนคิวแรงงาน" }
      ],
      immLogo: immLogo
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    }
  }
};
</script>

<style></style>
