<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col>
        <v-alert
          border="left"
          colored-border
          type="info"
          elevation="2"
          v-if="info.length != 0"
        >
          {{ info }}
        </v-alert>
      </v-col>
    </v-row>

    <!-- class="d-flex justify-center mb-6" -->
    <v-row justify="center" no-gutters>
      <v-col xs="12" sm="6" md="4" lg="3" align-self="center">
        <menu-card
          icon="mdi-account"
          text="
            Foreigner
          ចុះឈ្មោះដោយខ្លួនឯង
          ကိုယ်ပိုင်မှတ်ပုံတင်ခြင်း
          ລົງທະບຽນດ້ວຍຕົນເອງ
          "
          goto="/"
          :image="workers"
        />
      </v-col>
      <v-col xs="12" sm="6" md="4" lg="3">
        <menu-card
          icon="mdi-domain"
          text="นายจ้าง/ตัวแทน"
          goto="/agent_register"
          :image="agent"
        />
      </v-col>
    </v-row>
    <v-sheet color="transparent" class="d-flex flex-row justify-center">
    </v-sheet>
  </v-container>
</template>

<script>
import MenuCard from "./components/menu";
import workers from "../../assets/workers1_1.png";
import agent from "../../assets/agents1_1.png";
import { getSetting } from "@/utils/app.setting";
export default {
  components: { MenuCard },
  data() {
    return {
      workers: workers,
      agent: agent,
      info: ""
    };
  },
  created() {
    this.getNotice();
  },
  methods: {
    async getNotice() {
      let data = await getSetting("notice_menu_page");
      this.info = data;
    }
  }
};
</script>

<style></style>
