<template>
  <v-card :to="goto" max-width="400" class="ma-2" rounded>
    <v-img :src="image"></v-img>
    <!-- <v-container>
      <v-row align="center" class="fill-height">
        <v-col class="text-center" align-self="center">
          <v-icon size="200">{{ icon }}</v-icon>
        </v-col>
      </v-row>
    </v-container> -->
    <v-card-actions>
      <v-row>
        <v-col class="text-center">
          <h1>{{ text }}</h1>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["icon", "text", "textHtml", "goto", "image"],
  methods: {
    onClick() {
      console.log("click");
    }
  }
};
</script>

<style></style>
