import app from "./app.data";

const TokenKey = "tk";

export function getToken() {
  return app.get(TokenKey);
}

export function setToken(token) {
  return app.set(TokenKey, token);
}

export function removeToken() {
  return app.remove(TokenKey);
}
