import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import th from "vuetify/lib/locale/th";

Vue.use(Vuetify);

export default new Vuetify({
  // lang: {
  //   locales: { en },
  //   current: "en"
  // }
});
