<template>
  <v-app id="inspire">
    <top-bar />
    <v-main class="grey lighten-3">
      <!-- <v-container> -->
      <router-view />
      <!-- </v-container> -->
    </v-main>
    <footer-bar />
  </v-app>
</template>

<script>
import topBar from "./sections/topbar";
import footerBar from "./sections/footerbar";
export default {
  components: { topBar, footerBar }
};
</script>
